import gql from 'graphql-tag';

const getNominaQuery = gql `
    query nomina ($id: ID!) {
        nomina (id: $id) {
            id,
            procesando_timbres
        }
    }
`
const queries = {getNominaQuery};

export default queries;